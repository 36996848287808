import React, { Component } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { CTA } from '../components/CTA'
import { Styled } from '../styles/post.styles'
import { Link, graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'

class Template extends Component {
  render() {
    const defaultFrontmatter = {
      title: '',
      description: '',
      img: '',
      path: '',
      date: '',
    }

    const { markdownRemark } = this.props.data
    const { frontmatter = defaultFrontmatter, html = '' } = markdownRemark || {}
    const content = this.props.t('blogPost', { returnObjects: true })

    return (
      <Layout>
        <SEO
          title={frontmatter.title}
          description={frontmatter.description}
          image={frontmatter.img}
          pathname={frontmatter.path}
        />

        <Styled.Main>
          <div
            className="post-image"
            style={{ backgroundImage: `url(${frontmatter.img})` }}
          >
            <h1 className="post-title">{frontmatter.title}</h1>
            <h5 className="post-date">{frontmatter.date}</h5>
          </div>

          <section>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />

            <Link to={content.ctaUrl}>
              <CTA text={content.cta} underline />
            </Link>
          </section>
        </Styled.Main>
      </Layout>
    )
  }
}

export default withTranslation()(Template)

export const pageQuery = graphql`
  query ($path: String!, $language: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: $language)
        path
        title
        description
        img
        lang
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["global"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

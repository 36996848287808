import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  width: 100%;

  .post-image {
    position: relative;
    width: 100%;
    height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  }

  .post-title {
    position: relative;
    max-width: 85%;
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 6rem;
    line-height: 7.3rem;
    letter-spacing: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    z-index: 11;
  }

  .post-date {
    font-size: 1.9rem;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    margin-top: ${({ theme }) => theme.spacingS};
    z-index: 11;
  }

  section {
    padding-top: ${({ theme }) => theme.spacingL};
    padding-bottom: ${({ theme }) => theme.spacingXL};
  }

  .post-content {
    text-align: center;

    p {
      font-size: 2.6rem;
      line-height: 4.3rem;
      margin: ${({ theme }) => theme.spacingS} 0;
    }

    img {
      display: block;
      max-width: 100%;
      margin: ${({ theme }) => theme.spacingL} auto;
    }
  }

  .cta {
    display: block;
    margin: ${({ theme }) => `${theme.spacingL} auto 0 auto`};
  }

  @media screen and (max-height: 1100px) {
    .post-image {
      padding-top: 10rem;
      height: 65vh;
    }
  }

  @media screen and (max-width: 700px) {
    .post-title {
      font-size: 4rem;
      line-height: 5.3rem;
    }

    .post-date {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 400px) {
    .post-image {
      height: 40vh;
    }

    .post-title {
      font-size: 3rem;
      line-height: 3.9rem;
    }
  }
`

export const Styled = {
  Main,
}
